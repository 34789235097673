import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";
import { offline } from "./connectionService";

const m_checkLocalStore = () => {
	const store = localStorage.getItem("localizationData");

	if (store == null || store == "undefined") {
		return {};
	}

	try {
		return JSON.parse(store);
	} catch (err) {
		return {};
	}
};

const m_updateLocalStore = val => {
	localStorage.setItem("localizationData", JSON.stringify(val));
};

export const localizationData = writable(m_checkLocalStore());
localizationData.subscribe(m_updateLocalStore);

export const fetchLocalizationData = () => {
	const language = window.navigator.userLanguage || window.navigator.language;

	if (get_store_value(offline) === true) {
		localizationData.set({
			...get_store_value(localizationData),
			language,
		});
		return;
	}

	fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=ae40fc7e6f6842e8ab909980deb688ba")
		.then(res => res.json())
		.then(res => {
			localizationData.set({ language, ...res });
			console.log({ language, ...res });
		})
		.catch(err => {
			//localizationData.set({ ...get_store_value(localizationData), language })
		});
};
