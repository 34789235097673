import { writable } from "svelte/store";

export const connection = writable("online");
export const online = writable(true);
export const offline = writable(false);

window.ononline = () => {
	connection.set("online");
	online.set(true);
	offline.set(false);
};

window.onoffline = () => {
	connection.set("offline");
	online.set(false);
	offline.set(true);
};

/* let connectedRef = firebase.database().ref(".info/connected")
connectedRef.on('value', snap => {
    if (snap.val() === true) {
        console.log("connected");

        connection.set('online')
        online.set(true)
        offline.set(false)
    } else {
        console.log("not connected");

        connection.set('offline')
        online.set(false)
        offline.set(true)
    }
}) */
