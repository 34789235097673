import firebase from "firebase/compat/app";
import { get_store_value } from "svelte/internal";
import { config as configData, getEndpointAsync } from "./configService";
import { almost_there_flag, thank_you_flag } from "../pages/user/_components/stores";
import Cookies from "js-cookie";
import { writable } from "svelte/store";
export const user = writable(undefined);
import { updateDoc } from "./firestoreService";
export const signInWithGoogle = async () => {
    const config = get_store_value(configData)["authentication"];
    if (!config || !config.google.enabled)
        return;
    const provider = new firebase.auth.GoogleAuthProvider();
    config.google.scopes.forEach(scope => provider.addScope(scope));
    await signInWithRedirect(provider);
    almost_there_flag.set(true);
};
export const signInWithMicrosoft = async () => {
    const config = get_store_value(configData)["authentication"];
    if (!config || !config.microsoft.enabled)
        return;
    const provider = new firebase.auth.GoogleAuthProvider();
    config.microsoft.scopes.forEach(scope => provider.addScope(scope));
    await signInWithRedirect(provider);
};
export const signInWithApple = async () => {
    const config = get_store_value(configData)["authentication"];
    if (!config || !config.apple.enabled)
        return;
    const provider = new firebase.auth.GoogleAuthProvider();
    config.apple.scopes.forEach(scope => provider.addScope(scope));
    await signInWithRedirect(provider);
};
export const redirectResult = result => {
    if (!result.user)
        return;
    let event = "app_login";
    if (result?.additionalUserInfo.isNewUser === true)
        event = "app_sign_up";
    firebase.analytics().logEvent(event, {
        method: result.credential.providerId,
    });
    if (event === "app_sign_up") {
        const almostThereProviders = ["google.com", "apple.com", "microsoft.com"];
        if (result.user.providerData.some(provider => almostThereProviders.includes(provider.providerId))) {
            almost_there_flag.set(true);
            window.location.href = "/user/almost_there";
        }
        else {
            thank_you_flag.set(true);
            window.location.href = "/user/thank_you";
        }
    }
    else {
        window.location.href = "/app/files";
    }
};
const signInWithPopup = async (provider) => {
    try {
        const result = await firebase.auth().signInWithPopup(provider);
        //get_store_value(goto)('/')
        window.location.replace("/");
    }
    catch (err) { }
};
const signInWithRedirect = async (provider) => {
    try {
        await firebase.auth().signInWithRedirect(provider);
    }
    catch (err) { }
};
export const checkReflink = async (user, userData, localizationData) => {
    const raw_ref = Cookies.get("ref");
    if (raw_ref && user && userData) {
        try {
            const ref = JSON.parse(raw_ref);
            const token = await user.getIdToken();
            let endpoint;
            if (ref.value.startsWith("credits")) {
                endpoint =
                    (await getEndpointAsync("payment_endpoints")) +
                        "v1/coupons/use_onetime/" +
                        ref.value;
                const response = await fetch(endpoint, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                }).then(res => res.json());
                if (response.status === "ok") {
                    firebase.analytics().logEvent("credits_reflink_use", {
                        reflink: ref.value,
                    });
                    Cookies.remove("ref");
                }
                console.info(response);
            }
            else if (typeof userData?.ref === "undefined") {
                endpoint = (await getEndpointAsync("user")) + "ref";
                const response = await fetch(endpoint, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({
                        ref,
                        currency: localizationData?.country?.currency?.toLowerCase(),
                    }),
                }).then(res => res.json());
                if (response.status === "ok") {
                    firebase.analytics().logEvent("reflink_use", {
                        reflink: ref.value,
                    });
                    Cookies.remove("ref");
                }
                console.info(response);
            }
        }
        catch (err) {
            console.error(err);
        }
    }
    else if (raw_ref && userData && typeof userData?.ref !== "undefined") {
        Cookies.remove("ref");
    }
};
export const saveAdsData = () => {
    const queryParams = new URLSearchParams(location.search);
    if (Cookies.get("adsData")) {
        return;
    }
    if (!queryParams.has("utm_source") &&
        !queryParams.has("utm_medium") &&
        !queryParams.has("utm_campaign") &&
        !queryParams.has("utm_keyword") &&
        !queryParams.has("utm_content") &&
        !queryParams.has("gclid")) {
        return;
    }
    Cookies.set("adsData", JSON.stringify({
        utm_source: queryParams.get("utm_source"),
        utm_medium: queryParams.get("utm_medium"),
        utm_campaign: queryParams.get("utm_campaign"),
        utm_keyword: queryParams.get("utm_keyword"),
        utm_content: queryParams.get("utm_content"),
        gclid: queryParams.get("gclid"),
    }), {
        expires: 2,
    });
};
export const checkAdsData = async (user, userData) => {
    const adsData = Cookies.get("adsData");
    try {
        if (adsData && user && userData && userData.metadata?.refQuery === undefined) {
            const refQuery = JSON.parse(adsData);
            await updateDoc(`users/${user.uid}`, {
                metadata: {
                    refQuery,
                    ...(userData.metadata || {}),
                },
            });
            Cookies.remove("adsData");
        }
        else if (!adsData && user && userData && userData.metadata?.refQuery === undefined) {
            await updateDoc(`users/${user.uid}`, {
                metadata: {
                    refQuery: { gclid: null },
                    ...(userData.metadata || {}),
                },
            });
        }
        else if (adsData && user && userData) {
            Cookies.remove("adsData");
        }
    }
    catch (err) {
        console.error(err);
    }
};
