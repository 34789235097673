import HMR from "@roxi/routify/hmr";
import App from "./App.svelte";

window.workers = {};

window.workers.search = new Worker("/workers/search.js");

const app = HMR(App, { target: document.body }, "stenograf");

export default app;
