import firebase from "firebase/compat/app";
import promiseRetry from "promise-retry";
import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";
import { user } from "./authService";
import { getDocument, updateDoc } from "./firestoreService";
import { offline } from "./connectionService";
let uid = null;
let unSubscribe = null;
export const unSubscribeFiles = () => {
    if (unSubscribe) {
        unSubscribe();
        unSubscribe = null;
    }
};
export const filesStore = writable([]);
export const createInstance = () => {
    uid = get_store_value(user).uid;
    if (!uid)
        return;
    const filesRef = firebase
        .firestore()
        .collection("files")
        .where("user", "==", uid)
        .orderBy("createdAt", "desc");
    const sharedFilesRef = firebase
        .firestore()
        .collection("files")
        .where("userCanView", "array-contains", uid)
        .orderBy("createdAt", "desc");
    loadFiles(filesRef);
    loadFiles(sharedFilesRef, true);
};
const loadFiles = async (filesRef, shared = false) => {
    const latestFiles = await promiseRetry((retry, number) => {
        if (get_store_value(offline) === true)
            return null;
        try {
            return filesRef.get();
        }
        catch (error) {
            console.warn("[Files] " + number + " try failed.");
            retry(error);
        }
    });
    if (get_store_value(offline) === true)
        return;
    // Inserting and updating store
    filesStore.update(store => {
        const files = latestFiles.docs.map(doc => ({
            id: doc.id,
            shared,
            ...doc.data(),
        }));
        store = store.filter(f => f.shared === !shared);
        store = [...store, ...files];
        store = store.filter(f => f.id && f.createdAt && f.fileName && f.fileType && f.duration);
        store.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds || 0);
        return store;
    });
    unSubscribe = filesRef.onSnapshot(docs => {
        filesStore.update(store => {
            docs.docChanges().forEach(doc => {
                if (doc.type == "added") {
                    const index = store.findIndex(n => n.id == doc.doc.id);
                    if (index > -1) {
                        store[index] = {
                            id: doc.doc.id,
                            ...doc.doc.data(),
                        };
                    }
                    else {
                        store = [{ id: doc.doc.id, ...doc.doc.data() }, ...store];
                    }
                }
                if (doc.type == "modified") {
                    const index = store.findIndex(n => n.id == doc.doc.id);
                    if (index >= 0) {
                        store[index] = {
                            id: doc.doc.id,
                            ...doc.doc.data(),
                        };
                    }
                }
                if (doc.type == "removed") {
                    const index = store.findIndex(n => n.id == doc.doc.id);
                    if (index >= 0) {
                        store.splice(index, 1);
                    }
                }
            });
            store = store.filter(f => f.id);
            return store.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds || 0);
        });
    }, error => {
        console.error(error);
        if (unSubscribe)
            unSubscribe();
    });
    offline.subscribe(state => {
        if (state === true && unSubscribe)
            unSubscribe();
    });
};
/**
 * It listens to changes in the files collection and returns the changes to the callback function
 * @param {string} uid - The user ID of the user you want to listen to.
 * @param {IRegisterOptions} options - IRegisterOptions = { limit: 1000 }
 * @param {SnapshotCallback} callback - This is the function that will be called when a file is added,
 * removed, or updated.
 */
export const registerSnapshots = async (uid, options = { limit: 1000 }, callback) => {
    if (typeof uid !== "string")
        throw "UID is not valid";
    if (!callback)
        throw "Callback function was not passed";
    const refs = [
        firebase
            .firestore()
            .collection("files")
            .where("user", "==", uid)
            .orderBy("createdAt", "desc")
            .limit(options.limit),
        firebase
            .firestore()
            .collection("files")
            .where("usersCanView", "array-contains", uid)
            .where("isPublic", "==", true)
            .orderBy("createdAt", "desc")
            .limit(options.limit),
    ];
    const getFileHandler = (filesRef, onUpdate, onError, attempt) => {
        filesRef.onSnapshot(documents => {
            onUpdate(documents.docChanges().map(document => ({
                type: document.type,
                data: { id: document.doc.id, ...document.doc.data() },
            })));
        }, error => {
            onError({
                message: error.message,
                ref: filesRef,
                attempt: (attempt || 1) + 1,
            });
        });
    };
    const onUpdate = files => {
        callback(files);
    };
    const onError = async ({ message, ref, attempt }) => {
        console.error(`[${attempt || 1}] Error while listening file changes.`);
        console.error(message);
        await new Promise(res => setTimeout(res, (attempt || 1) * 1000));
        getFileHandler(ref, onUpdate, onError, attempt);
    };
    refs.map(ref => {
        getFileHandler(ref, onUpdate, onError);
    });
};
export const checkURLS = async (file) => {
    const m_file = file;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    if (!file.downloadURL || file.downloadURL == null || file.downloadURL == "") {
        try {
            m_file.downloadURL = await storageRef
                .child("uploads/" + file.id + "/original." + file.fileName.split(".").pop())
                .getDownloadURL();
            updateDoc("files/" + m_file.id, {
                downloadURL: m_file.downloadURL,
            });
        }
        catch (error) {
            console.error(error);
        }
    }
    if (!file.subtitlesURL || file.subtitlesURL == null || file.subtitlesURL == "") {
        try {
            m_file.subtitlesURL = await storageRef
                .child("subtitles/" + file.id + "/original.vtt")
                .getDownloadURL();
            updateDoc("files/" + m_file.id, {
                subtitlesURL: m_file.subtitlesURL,
            });
        }
        catch (error) {
            console.error(error);
        }
    }
    return m_file;
};
export const downloadRenderedVideo = async (fileId) => {
    const file = await getDocument(`files/${fileId}`);
    if (!file?.pathRendered)
        throw new Error("Could not find file to download");
    const fileName = (file.displayName || file.fileName || "Undefined").split(".")[0];
    const storage = firebase.storage();
    const storageRef = storage.refFromURL(file.pathRendered);
    const extension = storageRef.name.split(".").pop();
    const fileURL = await storageRef.getDownloadURL();
    await downloadURI(fileURL, `${fileName}-rendered.${extension}`);
    return null;
};
async function downloadURI(uri, name) {
    const response = await fetch(uri).then(r => r.blob());
    const blobUrl = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.download = name;
    link.href = blobUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
