import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export function createDoc(path, doc) {
    return firebase
        .firestore()
        .collection(path)
        .add({
        ...doc,
    });
}
export function updateDoc(path, doc) {
    return firebase
        .firestore()
        .doc(path)
        .set({
        ...doc,
    }, {
        merge: true,
    });
}
export function updateDocRef(docRef, doc) {
    return docRef.set({
        ...doc,
    }, {
        merge: true,
    });
}
export function deleteDoc(path) {
    return firebase.firestore().doc(path).delete();
}
export async function getCollection(path) {
    const colRef = await firebase.firestore().collection(path).get();
    return colRef.docs.map(doc => doc.data());
}
export async function getCollectionWithId(path) {
    const colRef = await firebase.firestore().collection(path).get();
    return colRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
}
export function getCollectionRef(path) {
    return firebase.firestore().collection(path);
}
export function getFilteredCollection(path, field, operator, value) {
    return firebase
        .firestore()
        .collection(path)
        .where(field, operator, value)
        .get()
        .then(res => {
        return res.docs.map(doc => doc.data());
    });
}
export function getDocument(path) {
    return firebase
        .firestore()
        .doc(path)
        .get()
        .then(res => {
        return res.data();
    });
}
export function getDocumentRef(path) {
    return firebase.firestore().doc(path);
}
export async function deleteVideo(fileIdsArr) {
    if (typeof fileIdsArr == "string")
        fileIdsArr = [fileIdsArr];
    fileIdsArr.forEach(async (fileId) => {
        return firebase
            .firestore()
            .collection("files")
            .doc(`${fileId}`)
            .delete()
            .then(() => {
            console.log("Document and nested files successfully deleted!");
        })
            .catch(error => {
            console.error("Error: ", error);
        });
    });
}
