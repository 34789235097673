<script>
	import { _ } from "svelte-i18n";

	import { fade } from "svelte/transition";
	import WifiOffIcon from "./UI/assets/WifiOffIcon.svelte";
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<!-- Global notification live region, render this permanently at the end of the document -->
<div
	aria-live="assertive"
	class="pointer-events-none fixed inset-0 z-600 flex items-end px-4 py-6 sm:items-end sm:p-6">
	<div class="flex w-full flex-col items-center space-y-4 sm:items-start">
		<!--
        Notification panel, dynamically insert this into the live region when it needs to be displayed
  
        Entering: "transform ease-out duration-300 transition"
          From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          To: "translate-y-0 opacity-100 sm:translate-x-0"
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
		<div
			in:fade={{ duration: 300 }}
			out:fade={{ duration: 100 }}
			class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
			<div class="p-4">
				<div class="flex items-start">
					<div class="flex-shrink-0">
						<WifiOffIcon class="h-6 w-6 text-red-400" />
					</div>
					<div class="ml-3 w-0 flex-1 pt-0.5">
						<p class="text-base font-medium text-gray-900">
							{$_("app.alerts.errors.offline_mode.title")}
						</p>
						<p class="mt-1 text-sm text-gray-500">
							{$_("app.alerts.errors.offline_mode.description")}
						</p>
					</div>
					<!-- <div class="ml-4 flex-shrink-0 flex">
              <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only high-contrast:text-yellow-50 high-contrast:bg-white">Close</span>
				<CloseIcon
					class="h-5 w-5"
				></CloseIcon>
              </button>
            </div> -->
				</div>
			</div>
		</div>
	</div>
</div>
