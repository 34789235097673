<script>
	import firebase from "firebase/compat/app";
	import {
		createStore,
		startUserTokenWatchdog,
		userData,
		userToken,
	} from "./services/userService";
	import { user } from "./services/authService";
	import { fetchPricingData } from "./services/pricingService";
	import { fetchLocalizationData, localizationData } from "./services/localizationService";
	import { config, fetchConfigData, overrideAdminConfig } from "./services/configService";
	import { offline } from "./services/connectionService";
	import OfflineAlert from "./components/OfflineAlert.svelte";
	import promiseRetry from "promise-retry";
	import { updateDoc } from "./services/firestoreService";
	import Cookies from "js-cookie";
	import { locale, locales } from "svelte-i18n";
	import { saveAdsData } from "./services/authService";
	import { onMount } from "svelte";
	import { FilesDB } from "./services/dbService";
	import { registerSnapshots } from "./services/filesService.ts";

	createStore();
	fetchConfigData();
	fetchLocalizationData();

	locale.subscribe(lang => {
		if (!lang) return;

		setTimeout(function () {
			fetchPricingData();
		}, 100);
	});

	localizationData.subscribe(lang => {
		fetchPricingData();
	});

	const searchParams = new URLSearchParams(window.location.search);
	const ref = searchParams.get("ref");

	if (ref) {
		if (!Cookies.get("ref")) {
			Cookies.set(
				"ref",
				JSON.stringify({
					timestamp: Date.now(),
					value: ref,
				}),
				{
					expires: 2,
				}
			);

			firebase.analytics().logEvent("reflink_set", {
				reflink: ref,
			});

			promiseRetry((retry, number) => {
				if (!$config?.redirects?.ref) return retry();
				return;
			}).then(() => {
				const red = $config?.redirects?.ref?.find(r => r.value === ref);
				if (red) {
					setTimeout(() => {
						window.location.href = red.redirect;
					}, 100);
				}
			});
		}
	}

	saveAdsData();

	let installEvent = null;

	window.addEventListener("beforeinstallprompt", e => {
		e.preventDefault();
		installEvent = e;
	});

	async function initializeFiles() {
		if (window.filesDB) {
			window.filesDB.user = $user.uid;
		} else {
			window.filesDB = new FilesDB({ user: $user.uid });
		}

		try {
			if (!window.filesDB.isOpen()) {
				await window.filesDB.open();
			}
		} catch (error) {
			console.error("Could not open filesDB");
			console.error(error);
		}

		try {
			// Loading configuration
			/* const data = firebase.remoteConfig().getValue("files_views").asString();
			const config = JSON.parse(data)["tablev2"]["search"]; */
			window.filesDB.config = $config?.files_views?.tablev2?.search;
		} catch (error) {
			console.error("Could not load configuration from remote config");
			console.error(error);
		}

		try {
			// Getting token from firebase
			window.filesDB.token = () => $userToken;
		} catch (error) {
			console.error("Could not get token from firebase");
			console.error(error);
		}

		window.filesDB.clearOthers($user.uid);
		window.filesDB.load();
		console.info("[FilesDB] Loaded");

		window.filesDB.onRemote = async files => {
			const FAIL = {};
			const promisses = [];

			for (const file of files) {
				promisses.push(firebase.firestore().doc(`files/${file}`).get());
			}

			try {
				let data = await Promise.all(promisses.map(promise => promise.catch(e => FAIL)));
				data = data.filter(result => result !== FAIL && result.exists);
				data = data.map(file => ({ id: file.id, ...file.data() }));
				return data;
			} catch (error) {
				console.error("Could not get information about files from firestore");
				console.error(error);
				return [];
			}
		};

		registerSnapshots($user.uid, { limit: 1000 }, files => {
			console.info("Files update", files);
			const filesToUpsert = files
				.filter(file => file.type === "added" || file.type === "modified")
				.map(file => file.data);
			const filesToRemove = files
				.filter(file => file.type === "removed")
				.map(file => file.data.id);

			if (filesToUpsert.length) window.filesDB.updateFiles(filesToUpsert);

			if (filesToRemove.length) window.filesDB.removeFiles(filesToRemove);
		});
	}

	onMount(() => {
		window.filesDB = new FilesDB();

		firebase.auth().onAuthStateChanged(currentUser => {
			if (currentUser) {
				user.set(currentUser);
			} else {
				user.set(null);
			}
		});
	});

	$: installEvent;
	$: $offline;

	$: {
		if ($userData.uid) {
			let register = {};
			const update = {};

			if ($userData.role === "admin") {
				overrideAdminConfig();
			}

			try {
				register = JSON.parse(sessionStorage.getItem("register_data_backup"));
			} catch (error) {
				console.error(error);
			}

			if (Object.keys(register || {}).length > 0) {
				if (!$userData.lang && register.lang) {
					update.lang = register.lang.split("-")[0];
					if (!$locales.includes(update.lang)) update.lan = "en";
				}

				if (!$userData.displayName && register.displayName)
					update.displayName = register.displayName;

				if (!$userData.address?.country && register.address?.country)
					update.address = { country: register.address.country };

				if (!$userData.consents && register.consents) update.consents = register.consents;

				if (Object.keys(update || {}).length > 0)
					updateDoc("users/" + $userData.uid, update).then(res => {
						if (update.lang) delete register.lang;
						if (update.displayName) delete register.displayName;
						if (update.consents) delete register.consents;
						if (update.address?.country) delete register.address.country;

						sessionStorage.setItem("register_data_backup", JSON.stringify(register));
					});
			}
		}
	}

	let lastUser = "";
	$: {
		if ($user && lastUser !== $user?.uid) {
			lastUser = $user.uid;
			startUserTokenWatchdog();
			initializeFiles();
		} else if ($user === null) {
			console.info("[FilesDB] Cleared");
			window.filesDB.clear();
		}
	}
</script>

<slot />

{#if $offline === true}
	<OfflineAlert />
{/if}
