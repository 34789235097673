import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";
export var ConditionType;
(function (ConditionType) {
    ConditionType[ConditionType["NotEqual"] = -2] = "NotEqual";
    ConditionType[ConditionType["Equal"] = 0] = "Equal";
    ConditionType[ConditionType["Lower"] = -1] = "Lower";
    ConditionType[ConditionType["LowerEqual"] = -10] = "LowerEqual";
    ConditionType[ConditionType["Greater"] = 1] = "Greater";
    ConditionType[ConditionType["GreaterEqual"] = 10] = "GreaterEqual";
    ConditionType[ConditionType["Between"] = 20] = "Between";
    ConditionType[ConditionType["In"] = 30] = "In";
})(ConditionType || (ConditionType = {}));
export const selectedTableFilters = writable([]);
export const addFilter = (filter) => {
    const filters = get_store_value(selectedTableFilters);
    selectedTableFilters.set([...filters, filter]);
};
export const removeFilter = (filter) => {
    const filters = get_store_value(selectedTableFilters);
    const filterIndex = filters.indexOf(filter);
    if (filterIndex !== -1) {
        filters.splice(filterIndex, 1);
        selectedTableFilters.set([...filters]);
    }
};
export function formatDate(date) {
    let month = "" + (date.getMonth() + 1), day = "" + date.getDate(), year = date.getFullYear();
    if (month.length < 2)
        month = "0" + month;
    if (day.length < 2)
        day = "0" + day;
    return [year, month, day].join("-");
}
export const datePrecisionDivider = 1000;
export const translationPrefix = "app.tablev2.filtersWindow.";
export const translationOptionPrefix = "app.tablev2.filtersWindow.options.";
export const filtersOptionsContext = Symbol("filtersOptionsContext");
