import Dexie from "dexie";
import FlexSearch from "../libs/flexsearch";
import { encode } from "../libs/flexsearch/lang/latin/advanced.js";
import promiseRetry from "promise-retry";
import { ConditionType } from "./filterService";
export class FilesDB extends Dexie {
    constructor(params = {}) {
        super("FilesData", { autoOpen: true, allowEmptyDB: true });
        this.limit = 25;
        this.page = 1;
        this.index = [];
        this.searchIndex = new FlexSearch({
            encode: encode,
            tokenize: "forward",
        });
        this.m_temporaryFiles = [];
        this.lastSearchIndex = -1;
        this.storage_limit = 1000;
        this.worker = undefined;
        this.m_remoteSearchHealthMs = 1000 * 20;
        this.m_workerCheckHealthMs = 1000 * 5;
        this.m_intervals = {
            searchHealthChecker: undefined,
        };
        this.m_callbacks = {};
        this.version(7).stores({
            files: "++id, createdAt, name, duration, language, owner, isOwner, users, paidCredits, paid, pathGeneral, pathOriginal, pathDASH, pathHLS, hasAudio, hasVideo, areSubtitlesReady, isVideoReady, areThumbnailsReady, isApproved, isChecked, isDiarizationReady, isTranscriptionReady, tmpUploaded, originalUploaded, isFavourite, opinionStars, lastUpdate, stage",
        });
        this.on("blocked", () => false);
        this.user = params.user || undefined;
        this.m_config = params.config || undefined;
        if (this.m_config)
            this.m_getSearchEndpoint();
        const tempFiles = localStorage.getItem("FilesDBTempFiles");
        if (tempFiles !== null) {
            this.m_temporaryFiles = JSON.parse(tempFiles);
        }
        this.m_initWorkerHealthChecker();
    }
    /**
     * Load index from database
     */
    async load() {
        const files = await this.files.toArray();
        files.map(file => {
            const index = this.index.find(index => index.file_id === file.id);
            if (!index || typeof index?.name !== "string")
                this.m_addIndex(file);
        });
        this.m_sendUpdate();
    }
    /**
     *
     * @param {File.Data} fileData File object
     * @returns index from database
     */
    async updateFile(fileData) {
        if (!this.user)
            throw "User is not set";
        if (this.index.find(index => index.file_id === fileData.id)) {
            this.m_addIndex(fileData);
            await this.files.update(fileData.id, this.m_convertFileToDB(fileData));
            this.m_sendUpdate();
        }
        else {
            this.m_addIndex(fileData);
            await this.files.add(this.m_convertFileToDB(fileData));
            this.m_sendUpdate();
        }
    }
    /**
     *
     * @param {IData} fileData File object
     *
     */
    addTemporaryFile(fileData) {
        const fileIndex = this.m_temporaryFiles.findIndex(f => f.id === fileData.id);
        if (fileIndex === -1) {
            this.m_temporaryFiles = [fileData, ...this.m_temporaryFiles];
            localStorage.setItem("FilesDBTempFiles", JSON.stringify(this.m_temporaryFiles));
        }
    }
    /**
     *
     * @param {IData[]} filesData File object
     *
     */
    removeTemporaryFiles(...filesData) {
        for (const fileData of filesData) {
            const fileIndex = this.m_temporaryFiles.findIndex(f => f.id === fileData.id);
            if (fileIndex !== -1) {
                this.m_temporaryFiles.splice(fileIndex, 1);
                this.m_temporaryFiles = [...this.m_temporaryFiles];
            }
        }
        localStorage.setItem("FilesDBTempFiles", JSON.stringify(this.m_temporaryFiles));
    }
    /**
     * It removes a file from the index and database
     * @param {string} fileId - The file id of the file to be removed from the index and database.
     */
    async removeFile(fileId) {
        const indexElem = this.index.find(index => index.file_id === fileId);
        if (this.worker) {
            this.worker.postMessage({
                command: "remove",
                args: indexElem.index,
            });
        }
        else {
            console.warn("Could not find worker");
            this.searchIndex.remove(indexElem.index);
        }
        this.index = this.index.filter(index => index.file_id !== fileId);
        await this.files.delete(fileId);
        this.m_sendUpdate();
    }
    /**
     * > If the number of files is greater than the storage limit, sort the files by their creation date
     * and keep the most recent ones, then add the file to the index if it's not already there, and
     * finally save the files to the database
     * @param {File.Data[]} filesData - File.Data[]
     * @returns The filesData is being returned.
     */
    async updateFiles(filesData) {
        if (!this.user)
            throw "User is not set";
        if (filesData.length > this.storage_limit) {
            filesData.sort((a, b) => a.createdAt.seconds -
                b.createdAt.seconds);
            filesData = filesData.slice(0, this.storage_limit);
        }
        filesData.map(fileData => {
            const index = this.index.find(index => index.file_id === fileData.id);
            if (!index || index?.name !== fileData.fileName)
                this.m_addIndex(fileData);
        });
        await this.files.bulkPut(filesData.map(fileData => this.m_convertFileToDB(fileData)));
        this.m_sendUpdate();
    }
    /**
     * It removes files from the search index and the database
     * @param {string[]} fileIds - An array of file ids to remove from the index.
     */
    async removeFiles(fileIds) {
        const indexElems = this.index.filter(index => fileIds.includes(index.file_id));
        if (this.worker) {
            this.worker.postMessage({
                command: "remove",
                args: indexElems.map(indexElem => indexElem.index),
            });
        }
        else {
            console.warn("Could not find worker");
            indexElems.map(indexElem => this.searchIndex.remove(indexElem.index));
        }
        this.index = this.index.filter(index => !fileIds.includes(index.file_id));
        await this.files.bulkDelete(fileIds);
        this.m_sendUpdate();
    }
    /**
     * It returns true if the file_id exists in the index, and false if it doesn't
     * @param {string} id - The id of the file you want to check.
     * @returns A boolean value.
     */
    checkFile(id) {
        return !!this.index.find(index => index.file_id === id)?.name;
    }
    /**
     * It checks if there are any files in the database that are not in the index
     * @returns The number of files that are not in the index.
     */
    async checkIndex() {
        let delta = 0;
        const files = await this.files.toArray();
        files.map(file => {
            if (!this.index.find(index => index.file_id === file.id))
                delta++;
        });
        return delta;
    }
    /**
     * It clears the index and the files
     * @returns A promise that resolves when the files are cleared.
     */
    clear() {
        if (this.worker) {
            this.worker.postMessage({
                command: "remove",
                args: this.index.map(i => i.index),
            });
        }
        else {
            console.warn("Could not find worker");
            this.index.map(i => i.index).forEach(index => this.searchIndex.remove(index));
        }
        this.index = [];
        return this.files.clear().then(this.m_sendUpdate);
    }
    /**
     * It removes all files from the database that don't belong to the user with the given id
     * @param {string} uid - The user id of the user to clear
     * @returns The number of files deleted.
     */
    async clearOthers(uid) {
        const files = this.files.filter(file => file.owner !== uid && !file.users.includes(uid));
        const ids = (await files.keys());
        if (this.worker) {
            this.worker.postMessage({
                command: "remove",
                args: this.index.filter(i => ids.includes(i.file_id)).map(i => i.index),
            });
        }
        else {
            console.warn("Could not find worker");
            this.index
                .filter(i => ids.includes(i.file_id))
                .map(i => i.index)
                .forEach(index => this.searchIndex.remove(index));
        }
        return await files.delete().then(res => {
            this.m_sendUpdate();
            return res;
        });
    }
    async get(options = {}) {
        const missingOptions = (!options.search || options.search?.length < 1) &&
            (!options.filters || options.filters?.length === 0);
        if (missingOptions && this.remote?.ok !== true) {
            const allFiles = this.files.orderBy("createdAt").reverse();
            const pageFiles = await allFiles
                .offset(this.limit * (this.page - 1))
                .limit(this.limit)
                .toArray();
            return {
                files: pageFiles.map(file => this.m_convertDBtoFile(file)),
                total: await this.files.count(),
                dbFilterOptions: [this.m_convertDBtoSharedUsers(await allFiles.toArray())],
                source: undefined,
            };
        }
        if (this.remote?.ok) {
            try {
                const searchOptions = missingOptions ? { search: "*" } : options;
                const files = {
                    ...(await this.m_searchRemote(searchOptions)),
                    source: "remote",
                };
                return files;
            }
            catch (error) {
                console.error("Could not get data from healthy server.", error);
            }
        }
        const havetoSearch = options.search || options.search?.length >= 1;
        const indexes = havetoSearch ? await this.m_searchLocal(options.search) : [];
        const allFilesRef = this.files
            .orderBy("createdAt")
            .reverse()
            .filter(file => (havetoSearch ? indexes.map(i => i.id).includes(file.id) : true))
            .filter(file => !Array.isArray(options.filters) ||
            options.filters.every(filter => {
                const field = file[filter.filter.field];
                switch (filter.condition) {
                    case ConditionType.Equal:
                        return filter.value.some(value => field == value);
                    case ConditionType.NotEqual:
                        return filter.value.every(value => field != value);
                    case ConditionType.Greater:
                        return filter.value.some(value => field > value);
                    case ConditionType.GreaterEqual:
                        return filter.value.some(value => field >= value);
                    case ConditionType.Lower:
                        return filter.value.some(value => field < value);
                    case ConditionType.LowerEqual:
                        return filter.value.some(value => field <= value);
                    case ConditionType.Between:
                        return field >= filter.value[0] && field <= filter.value[1];
                    case ConditionType.In:
                        return filter.value.includes(field);
                }
            }));
        const pageFiles = await allFilesRef
            .offset(this.limit * (this.page - 1))
            .limit(this.limit)
            .toArray();
        const allFilesCount = await allFilesRef.count();
        return {
            files: pageFiles.map(file => this.m_convertDBtoFile(file)),
            total: allFilesCount,
            dbFilterOptions: [this.m_convertDBtoSharedUsers(await allFilesRef.toArray())],
            source: "local",
        };
    }
    m_addIndex(fileData) {
        const index = this.index.findIndex(index => index.file_id === fileData.id);
        if (index >= 0) {
            this.index[index] = {
                ...this.index[index],
                file_id: fileData.id,
                name: fileData.fileName,
            };
            if (this.worker) {
                this.worker.postMessage({
                    command: "update",
                    args: [this.index[index].index, fileData.fileName],
                });
            }
            else {
                console.warn("Could not find worker");
                this.searchIndex.update(this.index[index].index, fileData.fileName);
            }
        }
        else {
            this.index.push({
                file_id: fileData.id,
                name: fileData.fileName,
                index: ++this.lastSearchIndex,
            });
            if (this.worker) {
                this.worker.postMessage({
                    command: "add",
                    args: [this.lastSearchIndex, fileData.fileName],
                });
            }
            else {
                console.warn("Could not find worker");
                this.searchIndex.add(this.lastSearchIndex, fileData.fileName);
            }
        }
    }
    set token(token) {
        if (typeof token === "string") {
            this.m_user_token = token;
            return;
        }
        this.m_callbacks.user_token = token;
    }
    set onUpdate(callback) {
        this.m_callbacks.on_update = callback;
    }
    set onRemote(callback) {
        this.m_callbacks.on_remote = callback;
    }
    set remoteHealthCheckInterval(ms) {
        if (this.m_intervals.searchHealthChecker)
            clearInterval(this.m_intervals.searchHealthChecker);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        this.m_intervals.searchHealthChecker = setInterval(function () {
            context.m_checkSearchHealth();
        }, ms);
        this.m_remoteSearchHealthMs = ms;
    }
    set workerHealthCheckInterval(ms) {
        if (this.m_intervals.workerHealthChecker)
            clearInterval(this.m_intervals.workerHealthChecker);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        this.m_intervals.workerHealthChecker = setInterval(function () {
            context.m_checkWorkerHealth();
        }, ms);
        this.m_workerCheckHealthMs = ms;
    }
    set config(config) {
        this.m_config = config;
        this.m_getSearchEndpoint();
    }
    get config() {
        return this.m_config;
    }
    m_getUserToken() {
        if (this.m_callbacks.user_token)
            return this.m_callbacks.user_token();
        if (this.m_user_token)
            return Promise.resolve(this.m_user_token);
        return undefined;
    }
    /**
     * This function takes a File.Data object and returns a FileInfo object.
     * @param fileData - File.Data
     * @returns The return type is FileInfo.
     */
    m_convertFileToDB(fileData) {
        //++id, createdAt, name, duration, language, owner, users, paidCredits, pathGeneral, hasAudio, hasVideo, areSubtitlesReady, isVideoReady, areThumbnailsReady, opinion
        return {
            id: fileData.id,
            createdAt: fileData.createdAt.seconds,
            name: fileData.fileName,
            duration: Number(fileData.duration || -1),
            language: fileData.language,
            paidCredits: Number(fileData.paidCredits || null),
            paid: Number(fileData.paid || false),
            pathGeneral: fileData.pathGeneral,
            pathOriginal: fileData.pathOriginal,
            pathDASH: fileData.pathDASH,
            pathHLS: fileData.pathHLS,
            users: Object.keys(fileData.users || {}),
            owner: fileData.user,
            isOwner: Number(fileData.user === this.user),
            hasAudio: Number(fileData.hasAudio || false),
            hasVideo: Number(fileData.hasVideo || false),
            areSubtitlesReady: Number(fileData.areSubtitlesReady || false),
            isVideoReady: Number(fileData.isVideoReady || false),
            areThumbnailsReady: Number(fileData.areThumbnailsReady || false),
            isApproved: Number(fileData.isApproved || false),
            isChecked: Number(fileData.isChecked || false),
            isDiarizationReady: Number(fileData.isDiarizationReady || false),
            isTranscriptionReady: Number(fileData.isTranscriptionReady || false),
            tmpUploaded: Number(fileData.tmpUploaded || false),
            originalUploaded: Number(fileData.originalUploaded || false),
            isFavourite: Number(fileData.opinion?.favourite || false),
            opinionStars: Number(fileData.opinion?.stars || null),
            lastUpdate: Number(fileData.lastUpdate || null),
            stage: fileData.stage || "",
        };
    }
    m_convertDBtoFile(fileData) {
        return {
            id: fileData.id,
            createdAt: fileData.createdAt,
            name: fileData.name,
            duration: fileData.duration,
            language: fileData.language,
            paidCredits: fileData.paidCredits,
            paid: Boolean(fileData.paid),
            pathGeneral: fileData.pathGeneral,
            pathOriginal: fileData.pathOriginal,
            pathDASH: fileData.pathDASH,
            pathHLS: fileData.pathHLS,
            owner: fileData.owner,
            users: (fileData.users || []).filter(uid => uid !== fileData.owner),
            isOwner: Boolean(fileData.isOwner),
            hasAudio: Boolean(fileData.hasAudio),
            hasVideo: Boolean(fileData.hasVideo),
            areSubtitlesReady: Boolean(fileData.areSubtitlesReady),
            isVideoReady: Boolean(fileData.isVideoReady),
            areThumbnailsReady: Boolean(fileData.areThumbnailsReady),
            isApproved: Boolean(fileData.isApproved),
            isChecked: Boolean(fileData.isChecked),
            isDiarizationReady: Boolean(fileData.isDiarizationReady),
            isTranscriptionReady: Boolean(fileData.isTranscriptionReady),
            tmpUploaded: Boolean(fileData.tmpUploaded),
            originalUploaded: Boolean(fileData.originalUploaded),
            isFavourite: Boolean(fileData.isFavourite),
            opinionStars: Number(fileData.opinionStars || null),
            lastUpdate: fileData.lastUpdate,
            stage: fileData.stage,
        };
    }
    m_initWorkerHealthChecker() {
        if (this.m_intervals.workerHealthChecker)
            clearInterval(this.m_intervals.workerHealthChecker);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        this.m_intervals.workerHealthChecker = setInterval(function () {
            context.m_checkWorkerHealth();
        }, this.m_workerCheckHealthMs);
    }
    /**
     * It checks if the worker is alive and if it is, it sets the worker variable to the worker
     * @returns The worker is being returned.
     */
    async m_checkWorkerHealth() {
        const worker = window.workers?.search || undefined;
        if (!worker)
            return undefined;
        const promise = new Promise(resolve => {
            const timeout = setTimeout(() => resolve(false), 100);
            worker.onmessage = event => {
                const message = event.data;
                if (message === "OK") {
                    clearTimeout(timeout);
                    resolve(true);
                }
            };
        });
        worker.postMessage({
            command: "ping",
        });
        const response = await promise;
        if (!response)
            return undefined;
        if (!this.worker) {
            //TODO: Load everything to worker
            console.info("Search index", this.searchIndex);
            this.searchIndex.export((key, data) => {
                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        console.error("Could not export indexes to worker.");
                        reject();
                    }, 10000);
                    worker.onmessage = event => {
                        const message = event.data;
                        if (message === "OK") {
                            clearTimeout(timeout);
                            resolve(true);
                        }
                    };
                    worker.postMessage({
                        command: "import",
                        args: [key, data],
                    });
                });
            });
        }
        this.worker = worker;
        return worker;
    }
    /**
     * It checks if the remote server is healthy
     * @returns a promise.
     */
    async m_checkSearchHealth() {
        try {
            const token = await this.m_getUserToken();
            //console.info("Token", token);
            const controller = new AbortController();
            if (!token)
                throw "User token does not exist";
            if (!this.remote.healthEndpoint)
                throw "Health endpoint is undefined";
            const timeoutId = setTimeout(() => controller.abort(), 1000);
            const response = await fetch(this.remote.healthEndpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                signal: controller.signal,
            });
            clearTimeout(timeoutId);
            const data = await response.json();
            if (data["webserver"] === true && data["searchengine"] === true) {
                this.remote = {
                    ...this.remote,
                    ok: true,
                };
                return true;
            }
            throw "Server is un-healthy";
        }
        catch (error) {
            console.warn(error);
            this.remote = {
                ...this.remote,
                ok: false,
            };
            return false;
        }
    }
    /**
     * "If the search is enabled, check the health of the search endpoint and set the remote object
     * accordingly."
     *
     * The first thing we do is check if the search is enabled. If it's not, we throw an error. If it is,
     * we check if the search endpoint and health endpoint are valid. If they're not, we throw an error.
     * If they are, we set the remote object accordingly
     * @returns The remote object is being returned.
     */
    m_getSearchEndpoint() {
        try {
            if (this.config["enabled"] !== true)
                throw "Search is disabled";
            if (typeof this.config["search_endpoint"] !== "string")
                throw "Search endpoint is invalid";
            if (typeof this.config["health_endpoint"] !== "string")
                throw "Health endpoint is invalid";
        }
        catch (error) {
            this.remote = {
                ok: false,
                healthEndpoint: undefined,
                searchEndpoint: undefined,
            };
            return;
        }
        this.remote = {
            ok: undefined,
            searchEndpoint: this.config["search_endpoint"],
            healthEndpoint: this.config["health_endpoint"],
        };
        promiseRetry(async (retry, attempt) => {
            const success = await this.m_checkSearchHealth();
            if (!success)
                retry(Error("Could not fetch endpoint"));
            console.info(`Fetched health endpoint in ${attempt} attempts.`);
        }, {
            retries: 10,
            maxTimeout: 3000,
        });
        this.remoteHealthCheckInterval = this.m_remoteSearchHealthMs;
    }
    async m_searchLocal(text) {
        const promise = new Promise(resolve => {
            if (this.worker) {
                let response = [];
                const timeout = setTimeout(() => resolve(response), 500);
                this.worker.onmessage = event => {
                    const message = event.data;
                    if (message === "OK") {
                        clearTimeout(timeout);
                        resolve(response);
                    }
                    else if (message === "ERROR") {
                        clearTimeout(timeout);
                        resolve([]);
                    }
                    else {
                        clearTimeout(timeout);
                        response = message;
                    }
                };
            }
            else {
                console.warn("Could not find worker");
                const response = this.searchIndex.search(text);
                resolve(response || []);
            }
        });
        if (this.worker) {
            this.worker.postMessage({
                command: "search",
                args: [text],
            });
        }
        let files = [];
        try {
            const indexes = await promise;
            console.info("Found indexes", indexes);
            files = indexes
                .map(i => {
                const info = this.index.find(index => index.index === i);
                if (!info)
                    return undefined;
                return {
                    id: info.file_id,
                    name: info.name,
                };
            })
                .filter(d => d);
        }
        catch (error) {
            console.warn("Could not find files");
        }
        return files;
    }
    async m_searchRemote(options = {}) {
        const token = await this.m_getUserToken();
        if (!token)
            throw "User token does not exist!";
        const query = {
            per_page: String(this.limit),
            page: String(this.page),
            timestamp: Date.now().toString(),
        };
        if (options.search?.length >= 1)
            query["query"] = options.search;
        if (options.filters?.length > 0)
            query["filter"] = this.m_translateFilters(options.filters);
        const response = await fetch(`${this.remote.searchEndpoint}?${new URLSearchParams(query).toString()}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        if (data["status"] !== "ok")
            throw "Bad response";
        // Load rest data
        const files = data.data?.hits?.map(hit => hit.document);
        const filesData = await this.files.bulkGet(files.map(file => file.id));
        const missingDBFiles = files.filter(file => !filesData.map(file => file?.id).includes(file.id));
        const missingTemporaryFiles = this.m_temporaryFiles.filter(file => !files.map(file => file?.id).includes(file.id));
        const filesToRequestRemote = [...missingDBFiles, ...missingTemporaryFiles];
        const now = Math.round(Date.now() / 1000);
        this.removeTemporaryFiles(...this.m_temporaryFiles.filter(file => files.map(file => file?.id).includes(file.id) || now - file.createdAt > 60));
        let restFilesData = [];
        try {
            restFilesData = await this.m_getRemote(filesToRequestRemote.map(file => file.id));
        }
        catch (error) {
            console.error(error);
        }
        const filesToHide = missingDBFiles
            .filter(file => !restFilesData.map(file => file.id).includes(file.id))
            .map(file => file.id);
        const convertedFilesData = filesData
            .filter(file => file?.id)
            .map(file => this.m_convertDBtoFile(file));
        console.info({ convertedFilesData, restFilesData });
        const result = [...missingTemporaryFiles, ...files]
            .map(file => Object.assign({}, convertedFilesData.find(fileData => fileData?.id === file.id) ||
            restFilesData.find(fileData => fileData?.id === file.id), file))
            .filter(file => !filesToHide.includes(file.id));
        return {
            total: data.data?.found,
            files: result,
            dbFilterOptions: [this.m_convertSharedUsersRemote(data?.data?.facet_counts)],
        };
    }
    m_sendUpdate() {
        if (this.m_callbacks.on_update)
            this.m_callbacks.on_update();
    }
    async m_getRemote(files) {
        if (!this.m_callbacks.on_remote)
            throw "onRemote handler is not registered";
        const data = (await this.m_callbacks.on_remote(files));
        return data.map(file => this.m_convertFileToDB(file));
    }
    m_convertSharedUsersRemote(facets) {
        const dbFilterOptions = {
            key: "users",
            values: [],
        };
        if (!facets) {
            return dbFilterOptions;
        }
        const users = facets.find(facet => facet.field_name === "users");
        if (!users) {
            return dbFilterOptions;
        }
        dbFilterOptions.values = users.counts.map(usr => usr.value).filter(uid => uid !== this.user);
        return dbFilterOptions;
    }
    m_convertDBtoSharedUsers(files) {
        const dbFilterOptions = {
            key: "users",
            values: [],
        };
        if (!files) {
            return dbFilterOptions;
        }
        dbFilterOptions.values = files
            .reduce((prev, curr) => {
            const users = [];
            if (curr.users && Array.isArray(curr.users)) {
                curr.users.forEach((usr) => {
                    if (!users.includes(usr) && !prev.includes(usr)) {
                        users.push(usr);
                    }
                });
            }
            return [...prev, ...users];
        }, [])
            .filter(uid => uid !== this.user);
        return dbFilterOptions;
    }
    m_translateFilters(filters) {
        let result = "";
        filters.forEach((filter, i, arr) => {
            result += `${filter.filter.field}:`;
            switch (filter.condition) {
                case ConditionType.Equal:
                    result += `=${filter.value[0]}`;
                    break;
                case ConditionType.NotEqual:
                    result += `!=${filter.value[0]}`;
                    break;
                case ConditionType.Greater:
                    result += `>${filter.value[0]}`;
                    break;
                case ConditionType.GreaterEqual:
                    result += `>=${filter.value[0]}`;
                    break;
                case ConditionType.Lower:
                    result += `<${filter.value[0]}`;
                    break;
                case ConditionType.LowerEqual:
                    result += `<=${filter.value[0]}`;
                    break;
                case ConditionType.Between:
                    result += `[${filter.value[0]}..${filter.value[1]}]`;
                    break;
                case ConditionType.In:
                    result += `[${filter.value.join(", ")}]`;
                    break;
            }
            if (i < arr.length - 1)
                result += " && ";
        });
        console.info("Translating filter", filters, "to", result);
        return result;
    }
}
export class FilesViewport {
    constructor(options = {}) {
        this.files = [];
        this.db = undefined;
        this.searching = "";
        this.filters = [];
        this.filesUpdateCallback = null;
        this.db = options.db ? options.db : undefined;
        this.page = options.page ? options.page : 1;
        this.pagesize = options.pagesize ? options.pagesize : 25;
    }
    set database(_db) {
        this.db = _db;
        this.db.limit = this.pagesize;
        this.db.page = this.page;
        this.db.onUpdate = () => this.m_onUpdateHandler();
        this.m_refreshFiles();
    }
    onUpdate(callback) {
        this.filesUpdateCallback = callback;
    }
    checkDatabase() {
        return !!this.db;
    }
    setPagesize(size, refresh = true) {
        this.pagesize = size;
        if (this.db)
            this.db.limit = size;
        if (refresh)
            this.m_refreshFiles();
    }
    setPage(page, refresh = true) {
        if (page > 0 && Number.isInteger(page)) {
            this.page = page;
            if (this.db)
                this.db.page = page;
            if (refresh)
                this.m_refreshFiles();
        }
    }
    search(text, refresh = true) {
        if (!this.db)
            throw new Error("Could not connect to database");
        this.searching = text;
        if (refresh)
            this.m_refreshFiles();
    }
    filter(filters, refresh = true) {
        if (!this.db)
            throw new Error("Could not connect to database");
        this.filters = filters;
        if (refresh)
            this.m_refreshFiles();
    }
    refresh() {
        this.m_refreshFiles();
    }
    m_onUpdateHandler() {
        this.m_refreshFiles();
    }
    async m_refreshFiles() {
        const options = {};
        if (this.searching && typeof this.searching === "string" && this.searching.length > 0)
            options["search"] = this.searching;
        if (this.filters && Array.isArray(this.filters) && this.filters.length > 0)
            options["filters"] = this.filters;
        console.info("Searching with options", options);
        const response = await this.db?.get(options);
        this.files = response.files || [];
        this.total = response.total || 0;
        this.dbFilterOptions = response.dbFilterOptions || [];
        this.source = (response.source || undefined);
        try {
            if (this.filesUpdateCallback)
                this.filesUpdateCallback();
        }
        catch (error) {
            console.warn(error);
        }
    }
}
