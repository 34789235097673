
/**
 * @roxi/routify 2.18.17
 * File generated Wed Nov 13 2024 10:38:09 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.17"
export const __timestamp = "2024-11-13T10:38:09.190Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes.js"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isPage": true,
              "path": "/app/account/billing",
              "id": "_app_account_billing",
              "component": () => import('../src/pages/app/account/billing.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/edit",
              "id": "_app_account_edit",
              "component": () => import('../src/pages/app/account/edit.svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/app/account/index",
              "id": "_app_account_index",
              "component": () => import('../src/pages/app/account/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/integrations",
              "id": "_app_account_integrations",
              "component": () => import('../src/pages/app/account/integrations.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/notifications",
              "id": "_app_account_notifications",
              "component": () => import('../src/pages/app/account/notifications.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/password",
              "id": "_app_account_password",
              "component": () => import('../src/pages/app/account/password.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/preview",
              "id": "_app_account_preview",
              "component": () => import('../src/pages/app/account/preview.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/account/settings",
              "id": "_app_account_settings",
              "component": () => import('../src/pages/app/account/settings.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "path": "/app/account",
          "id": "_app_account__layout",
          "component": () => import('../src/pages/app/account/_layout.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isPage": true,
              "path": "/app/editor/:id",
              "id": "_app_editor__id",
              "component": () => import('../src/pages/app/editor/[id].svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "isReset": true,
          "path": "/app/editor",
          "id": "_app_editor__reset",
          "component": () => import('../src/pages/app/editor/_reset.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/app/files/index",
              "id": "_app_files_index",
              "component": () => import('../src/pages/app/files/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/app/files/new",
              "id": "_app_files_new",
              "component": () => import('../src/pages/app/files/new.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "path": "/app/files",
          "id": "_app_files__layout",
          "component": () => import('../src/pages/app/files/_layout.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/app/index",
          "id": "_app_index",
          "component": () => import('../src/pages/app/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/app",
      "id": "_app__layout",
      "component": () => import('../src/pages/app/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/auth/index",
          "id": "_auth_index",
          "component": () => import('../src/pages/auth/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/auth/signin",
          "id": "_auth_signin",
          "component": () => import('../src/pages/auth/signin.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/auth/signup",
          "id": "_auth_signup",
          "component": () => import('../src/pages/auth/signup.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/auth",
      "id": "_auth__layout",
      "component": () => import('../src/pages/auth/_layout.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/payment/credits",
          "id": "_payment_credits",
          "component": () => import('../src/pages/payment/credits.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/payment/index",
          "id": "_payment_index",
          "component": () => import('../src/pages/payment/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/payment/plans",
          "id": "_payment_plans",
          "component": () => import('../src/pages/payment/plans.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isPage": true,
              "path": "/payment/summary/canceled",
              "id": "_payment_summary_canceled",
              "component": () => import('../src/pages/payment/summary/canceled.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/payment/summary/error",
              "id": "_payment_summary_error",
              "component": () => import('../src/pages/payment/summary/error.svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/payment/summary/index",
              "id": "_payment_summary_index",
              "component": () => import('../src/pages/payment/summary/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/payment/summary/sub_canceled",
              "id": "_payment_summary_sub_canceled",
              "component": () => import('../src/pages/payment/summary/sub_canceled.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "isReset": true,
          "path": "/payment/summary",
          "id": "_payment_summary__reset",
          "component": () => import('../src/pages/payment/summary/_reset.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/payment",
      "id": "_payment__layout",
      "component": () => import('../src/pages/payment/_layout.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/privacy",
      "id": "_privacy",
      "component": () => import('../src/pages/privacy.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/terms",
      "id": "_terms",
      "component": () => import('../src/pages/terms.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/user/action",
          "id": "_user_action",
          "component": () => import('../src/pages/user/action.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/user/almost_there",
          "id": "_user_almost_there",
          "component": () => import('../src/pages/user/almost_there.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/user/code",
          "id": "_user_code",
          "component": () => import('../src/pages/user/code.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/user/index",
          "id": "_user_index",
          "component": () => import('../src/pages/user/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/user/thank_you",
          "id": "_user_thank_you",
          "component": () => import('../src/pages/user/thank_you.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/user/verify",
          "id": "_user_verify",
          "component": () => import('../src/pages/user/verify.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/user",
      "id": "_user__layout",
      "component": () => import('../src/pages/user/_layout.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

